.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-grid {
  margin-bottom: 5vmin;
}

.promo-footer {
  margin-bottom: 5vmin;
}

.no-menu-card-grid {
  margin-bottom: 200px !important;
}

.hero-container {
  font-size: 1.5em;
  margin-top: 5vmin;
}

.feature-container {
  font-size: 1.3em;
}
.apple-button {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10vmin;
}

.login-image {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-gradient {
  background: rgb(253,109,29);
  background: linear-gradient(90deg, rgba(253,109,29,0.9037990196078431) 0%, rgba(253,162,29,0.9037990196078431) 85%);
}

.sectionName {
  font-weight: 700 !important;
}

.section-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-menu-header {
  align-items: center;
  display: flex;
  vertical-align: middle;
  flex-direction: row;
  justify-content: center;
}

.nav-title {
  vertical-align: middle;
  margin-bottom: 8px;
}

.google-play-badge {
  margin-top: -10px;
}

p > span {
  opacity: 0.4;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 /* DarkMode */
@media (prefers-color-scheme: dark) {
  .App-link {
    color: @inherit blue;
  }
  .ui.list .list>.item .header, .ui.list>.item .header {
    color: white;
  }
  .ui.huge.header .sub.header {
    color: white;
  }
}
