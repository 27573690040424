body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: rgb(38,38,38);
    color: white;
  }
  .ui.header {
    color: white;
  }
  .ui.divider:not(.vertical):not(.horizontal) {
    border-top: 1px solid rgb(105,105,105);
    border-bottom: 1px rgb(105,105,105);
  }
  .ui.selection.list .list>.item:active, .ui.selection.list>.item:active {
    background: rgb(192,192,192);
    color: black;
  }
  .ui.divided.list>.item {
    border-top: 1px solid rgb(105,105,105);
  }

  .ui.divided.list>.item ::selection {
    background: rgb(105,105,105);
  }
  div.content {
    color: white;
  }
  .sub.header {
    color: white;
  }

  .ui.segment {
    background: rgb(38,38,38);
    border-color: rgb(105,105,105);
  }
}
